import { useNavigate, useParams } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';

import { formatTypeformSlug } from '../../components/Event';
import { ErrorMessage } from '../../components/Event/ErrorMessage';
import { GlobalLoading } from '../../components/GlobalLoading';
import { PublicHomeLayout } from '../../components/PublicHome/Layout';
import { getVenueSlug } from '../../components/Venue/VenueProvider';
import logger from '../../logger/logger';
import { apiService } from '../../services/api-service';

// eslint-disable-next-line import/no-default-export
export default function EventCohost(): JSX.Element {
  const { eventId } = useParams<'eventId'>();

  const navigate = useNavigate();

  const running = useRef(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    async function exec() {
      try {
        if (!eventId) throw new Error('No event ID');
        if (running.current) return;

        running.current = true;

        const {
          data: { event },
        } = await apiService.event.getPublicEvent(eventId);
        const venueSlug = getVenueSlug(event);
        if (!venueSlug) throw new Error('Cannot resolve venue');

        const params = new URLSearchParams();
        params.set('event-id', event.id);
        params.set('featured-game', event.packId);
        params.set('cohost', 'enabled');
        // params.set('virtual-background', 'enabled');
        params.set('cohost-video-mixer', 'enabled');
        params.set(
          'organization',
          formatTypeformSlug(event.orgName ?? '', event.startAt)
        );
        params.set('cohost-panel', 'open');
        params.set('cohost-team-randomization', 'animated');

        navigate({
          pathname: `/venue/${venueSlug}`,
          search: params.toString(),
        });
      } catch (cause) {
        const error = new Error('Failed to navigate to event', { cause });
        logger.error('failed to navigate to event', error);
        setError(error);
      } finally {
        running.current = false;
      }
    }

    exec();
  }, [eventId, navigate]);

  if (error) {
    return (
      <PublicHomeLayout>
        <div className='h-full flex-1 bg-game-library bg-w-full bg-no-repeat bg-top bg-local flex justify-center'>
          <ErrorMessage
            title='Something Went Wrong'
            body='We had trouble loading this event. Please try again.'
          />
        </div>
      </PublicHomeLayout>
    );
  }

  return <GlobalLoading />;
}
